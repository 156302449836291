import {
    NgModule
} from '@angular/core';
import {
    PreloadAllModules,
    RouterModule,
    Routes
} from '@angular/router';




const routes: Routes = [{
        path: 'login',
        loadChildren: () => import('./login/login.module').then(m => m.LoginPageModule)
    },
    {
        path: '',
        redirectTo: 'login',
        pathMatch: 'full'
    },
    {
        path: 'home',
        loadChildren: () => import('./home/home.module').then(m => m.HomePageModule)
    },
    {
        path: 'user',
        loadChildren: () => import('./user/user.module').then(m => m.UserPageModule)
    },
    {
        path: 'buyershadedashbord',
        loadChildren: () => import('./buyershadedashbord/buyershadedashbord.module').then(m => m.BuyershadedashbordPageModule)
    },

    {
        path: 'season',
        loadChildren: () => import('./season/season.module').then(m => m.SeasonPageModule)
    },


    {
        path: 'sampletype',
        loadChildren: () => import('./sampletype/sampletype.module').then(m => m.SampletypePageModule)
    },


    {
        path: 'fabricinfo',
        loadChildren: () => import('./fabricinfo/fabricinfo.module').then(m => m.FabricinfoPageModule)
    },


    {
        path: 'componentfamily',
        loadChildren: () => import('./componentfamily/componentfamily.module').then(m => m.ComponentfamilyPageModule)
    },


    {
        path: 'components',
        loadChildren: () => import('./components/components.module').then(m => m.ComponentsPageModule)
    },

    {
        path: 'color',
        loadChildren: () => import('./color/color.module').then(m => m.ColorPageModule)
    },


    {
        path: 'supplier',
        loadChildren: () => import('./supplier/supplier.module').then(m => m.SupplierPageModule)
    },
	
    {
        path: 'ordertype',
        loadChildren: () => import('./ordertype/ordertype.module').then(m => m.OrdertypePageModule)
    },
	
	 {
        path: 'quality',
        loadChildren: () => import('./quality/quality.module').then(m => m.QualityPageModule)
    },


    {
        path: 'poentrysample',
        loadChildren: () => import('./poentrysample/poentrysample.module').then(m => m.PoentrysamplePageModule)
    },


    {
        path: 'poentrybulk',
        loadChildren: () => import('./poentrybulk/poentrybulk.module').then(m => m.PoentrybulkPageModule)
    },

    {
        path: 'poentrybulkcompleted',
        loadChildren: () => import('./poentrybulkcompleted/poentrybulkcompleted.module').then(m => m.PoentrybulkcompletedPageModule)
    },

    {
        path: 'poentrybulkcurrent',
        loadChildren: () => import('./poentrybulkcurrent/poentrybulkcurrent.module').then(m => m.PoentrybulkcurrentPageModule)
    },

    {
        path: 'poentrybulkupcoming',
        loadChildren: () => import('./poentrybulkupcoming/poentrybulkupcoming.module').then(m => m.PoentrybulkupcomingPageModule)
    },
    {
        path: 'poentrybulkerp',
        loadChildren: () => import('./poentrybulkerp/poentrybulkerp.module').then(m => m.PoentrybulkerpPageModule)
    },


    {
        path: 'povalidationsample',
        loadChildren: () => import('./povalidationsample/povalidationsample.module').then(m => m.PovalidationsamplePageModule)
    },

    {
        path: 'poclosesample',
        loadChildren: () => import('./poclosesample/poclosesample.module').then(m => m.PoclosesamplePageModule)
    },


    {
        path: 'povalidationbulk',
        loadChildren: () => import('./povalidationbulk/povalidationbulk.module').then(m => m.PovalidationbulkPageModule)
    },
    {
        path: 'povalidationbulk1',
        loadChildren: () => import('./povalidationbulk1/povalidationbulk1.module').then(m => m.Povalidationbulk1PageModule)
    },


    
    {
        path: 'povalidationbulkadd1',
        loadChildren: () => import('./povalidationbulkadd1/povalidationbulkadd1.module').then(m => m.Povalidationbulkadd1PageModule)
    },


    
        {
        path: 'povalidationbulkcomp',
        loadChildren: () => import('./povalidationbulkcomp/povalidationbulkcomp.module').then(m => m.PovalidationbulkcompPageModule)
    },
    
    

    {
        path: 'poclosebulk',
        loadChildren: () => import('./poclosebulk/poclosebulk.module').then(m => m.PoclosebulkPageModule)
    },


    {
        path: 'changepassword',
        loadChildren: () => import('./changepassword/changepassword.module').then(m => m.ChangepasswordPageModule)
    },

    {
        path: 'rolepermission',
        loadChildren: () => import('./rolepermission/rolepermission.module').then(m => m.RolepermissionPageModule)
    },
    {
        path: 'role',
        loadChildren: () => import('./role/role.module').then(m => m.RolePageModule)
    },
    {
        path: 'userpermission',
        loadChildren: () => import('./userpermission/userpermission.module').then(m => m.UserpermissionPageModule)
    },


    {
        path: 'datapermission',
        loadChildren: () => import('./datapermission/datapermission.module').then(m => m.DatapermissionPageModule)
    },
    {
        path: 'hotreports',
        loadChildren: () => import('./hotreports/hotreports.module').then(m => m.HotreportsPageModule)
    },
    {
        path: 'spl',
        loadChildren: () => import('./spl/spl.module').then(m => m.SplPageModule)
    },
    {
        path: 'reports',
        loadChildren: () => import('./reports/reports.module').then(m => m.ReportsPageModule)
    }, 
    {
        path: 'selection',
        loadChildren: () => import('./selection/selection.module').then(m => m.SelectionPageModule)
    },
    {
        path: 'reasonrehd',
        loadChildren: () => import('./reasonrehd/reasonrehd.module').then(m => m.ReasonrehdPageModule)
    },
    {
        path: 'liftplan',
        loadChildren: () => import('./liftplan/liftplan.module').then(m => m.LiftplanPageModule)
    },
    {
        path: 'glossary',
        loadChildren: () => import('./glossary/glossary.module').then(m => m.GlossaryPageModule)
    },
    {
        path: 'alerts',
        loadChildren: () => import('./alerts/alerts.module').then(m => m.AlertsPageModule)
    },
    {
        path: 'reportsample',
        loadChildren: () => import('./reportsample/reportsample.module').then(m => m.ReportsamplePageModule)
    },

    {
        path: 'tnarealizationreport',
        loadChildren: () => import('./tnarealizationreport/tnarealizationreport.module').then(m => m.TnarealizationreportPageModule)
      },
    {
        path: 'reportselection',
        loadChildren: () => import('./reportselection/reportselection.module').then(m => m.ReportselectionPageModule)
    },
	
    {
        path: 'reportehdpassed',
        loadChildren: () => import('./reportehdpassed/reportehdpassed.module').then(m => m.ReportehdpassedPageModule)
    },

    {
        path: 'tnaerp',
        loadChildren: () => import('./tnaerp/tnaerp.module').then(m => m.TnaerpPageModule)
    },

    {
        path: 'timeandactionplanning',
        loadChildren: () => import('./timeandactionplanning/timeandactionplanning.module').then(m => m.TimeandactionplanningPageModule)
    },

    {
        path: 'tna',
        loadChildren: () => import('./tna/tna.module').then(m => m.TnaPageModule)
    },
    {
        path: 'omf',
        loadChildren: () => import('./omf/omf.module').then(m => m.OmfPageModule)
    },
  {
    path: 'perimeter',
    loadChildren: () => import('./perimeter/perimeter.module').then( m => m.PerimeterPageModule)
  },
  {
    path: 'pagepermission',
    loadChildren: () => import('./pagepermission/pagepermission.module').then( m => m.PagepermissionPageModule)
  },
  {
    path: 'tnaconfirm',
    loadChildren: () => import('./tnaconfirm/tnaconfirm.module').then(m => m.TnaconfirmPageModule)
},
{
    path: 'postatus',
    loadChildren: () => import('./postatus/postatus.module').then(m => m.PostatusPageModule)
},

{
    path: 'fgstockreport',
    loadChildren: () => import('./fgstockreport/fgstockreport.module').then(m => m.FgstockreportPageModule)
},
  {
    path: 'component',
    loadChildren: () => import('./component/component.module').then( m => m.ComponentPageModule)
  },

  {
    path: 'yarnrequirement',
    loadChildren: () => import('./yarnrequirement/yarnrequirement.module').then(m => m.YarnrequirementPageModule)
  },
  {
    path: 'productDevelopment',
    loadChildren: () => import('./productDevelopment/productDevelopment.module').then(m => m.ProductDevelopmentPageModule)
},

{
    path: 'proddevtracking',
    loadChildren: () => import('./proddevtracking/proddevtracking.module').then(m => m.ProddevtrackingPageModule)
},

{
    path: 'productsummarystatus',
    loadChildren: () => import('./productsummarystatus/productsummarystatus.module').then(m => m.ProductsummarystatusPageModule)
},
 {
    path: 'productcatalog',
    loadChildren: () => import('./productcatalog/productcatalog.module').then(m => m.ProductcatalogPageModule)
}

];


@NgModule({
    imports: [
        // RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })

        RouterModule.forRoot(routes, {
            onSameUrlNavigation: 'reload', 
  
        })



    ],
    exports: [RouterModule]
})
export class AppRoutingModule {}
