import {
    Injectable
} from '@angular/core';
import {
    HttpClient,HttpHeaders
} from '@angular/common/http';

import {
    Location
} from '@angular/common';

 


import {
    LoadingController,
    AlertController,
    ToastController,
    MenuController
} from '@ionic/angular';
import {
    Router
} from '@angular/router';

import {
    Base64
} from 'js-base64';

import {
    Subject,
    Observable
} from 'rxjs';

import {
    MenuItem
} from 'primeng-lts/api';


import * as $ from "jquery";


import {
    DomSanitizer
} from '@angular/platform-browser';
import { ValueConverter } from '@angular/compiler/src/render3/view/template';



//import { encode, decode } from 'js-base64';

@Injectable({
    providedIn: 'root'
})
export class AuthService {

    //////////////////////////////////////////////////////////////////////////////

   // apiUrl = "http://192.168.16.218:8000/";

  //  apiUrl = "http://192.168.16.106:8000/";

   //apiUrl = "http://192.168.16.127/smt/";

       // apiUrl = "https://compobooking.com/console/";


     //  apiUrl = "http://staging.compobooking.com/console/";
     
     
     
          apiUrl = "http://stagingpy.compobooking.com/console/";

    /////////////////////////////////////////////////////////////


    //////////////////////////////////////////////////////////////////////////

    showTracer = 0;

    navigateItems: MenuItem[];

    myToast: any;

    appTitle = "Component Booking";

    copyrightsTxt = '<span title = "Support" >      <a href="https://compobooking.com/helpdesk/"  target="_blank"><img src="../../assets/image/help.png"> <i>Compobooking Help Desk</i></a>  </span> <span > Copyright @ 2021 onwards, White House Business Solutions P Ltd; <b><a href="../../assets/data/compobooking_app_release_notes.pdf"  target="_blank" title = "What\'\s new" >Version 8.2.0</a> </b> (Build: 20220804) </span>';


    // qtyExceededMsg = "Quantity should not exceed assigned/1800 Kgs";

    qtyExceededMsg = "Quantity should not exceed";


    qtyMinimumMsg = "Minimum qty should not be lesser selection minimum qty";


    application_url: any = '';

    userData: any = [];

    menuData: any = [];
    
    activeusername = '';

    isLoading = false;

    ipaddress:any='';
    repeatServiceVoidTime = 0;


    versionControllNum = 0;


    private subject = new Subject<any>();

    getlocalvalueInterval: any;

    constructor(public http: HttpClient, public loadingController: LoadingController,
        public alertController: AlertController, private router: Router,

        private toast: ToastController


        , public menuCtrl: MenuController,

        location: Location, private sanitized: DomSanitizer

    ) {




        /* 
            
                 confirm("USE CHROME FOR '--LOCAL--' DB AND FIREFOX FOR '--STAGING--'  DB");
        
                   let chromeAgent = navigator.userAgent.indexOf("Chrome") > -1;
        
                   this.apiUrl= (chromeAgent)?"http://192.168.16.127/smt/":  
                   "http://staging.compobooking.com/console/"; 
        
         */

        this.sanitized.bypassSecurityTrustStyle(this.copyrightsTxt);

        this.sanitized.bypassSecurityTrustHtml(this.copyrightsTxt);

  

        let loca = String(window.location);


        /*  
        
                if (loca.indexOf('staging.compobooking.com') >= 0) {
        
                    this.apiUrl == 'http://staging.compobooking.com/console/';
        
                } else if (loca.indexOf('compobooking.com') >= 0) {
        
                    this.apiUrl == 'https://compobooking.com/console/';
        
                } else {
        
                    this.apiUrl = "http://192.168.16.127/smt/";
        
                }
                
                
                */








        if (this.apiUrl == 'https://compobooking.com/console/' && loca.indexOf('compobooking.com') < 0) confirm('***ALERT***  LIVE DB CONNECTED');


        if (this.apiUrl == 'http://192.168.16.127/smt/') confirm('HI - LOCAL DB ONLY');



        this.application_url = loca.substr(0, loca.lastIndexOf('/'));


        //  alert(this.application_url);


        router.events.subscribe(val => {
            if (location.path() != "") {
                // alert(location.path());
            } else {
                // this.route = "Home";
            }
        });

        this.navigateItems = [{
            label: 'Change Password',
            icon: 'pi pi-cog',
            // styleClass:'OrangeButton',
            command: () => {
                this.navigatefun('changepassword');
            }

            // routerLink: ['/changepassword']
        },

        {
            label: 'Logout',
            icon: 'pi pi-sign-out',
            command: () => {
                this.navigatefun('logout');
            }

        },

        {
            label: 'Glossary',
            icon: 'pi pi-file-pdf',
            command: () => {
                this.navigatefun('glos');
            }

        },

        {
            label: 'Formulae',
            icon: 'pi pi-file-pdf',
            command: () => {
                this.navigatefun('form');
            }

        },

        {
            label: 'Page Help',
            icon: 'pi pi-exclamation-circle',
            command: () => {
                this.navigatefun('help');
            }

        }


        ];

        this.getlocalvalueInterval = setInterval(() => {

            this.userData = localStorage.getItem("SMTOOL_loginstorage");

            if (this.userData) {

                this.userData = JSON.parse(this.userData);

                if (this.userData.hasOwnProperty['logindetails']) {

                    //  clearInterval(this.getlocalvalueInterval)

                } else {

                    //   this.navigatefun('logout');


                }

                this.activeusername = this.userData['logindetails'][0]['username'];

                // clearInterval(this.getlocalvalueInterval)

            }

        }, 500)


        setTimeout(() => {
            this.repeatServiceVoidTime = 1;

          //  this.versioncontrolFun();

        }, 5000);

    }



    versioncontrolFun() {

        if (this.router.url.indexOf("login") >= 0) return;

        var vct = localStorage.getItem("versionControllNum");

        let path = { "path": "versioncontrol/" };

        this.callservice(path).then(result => {

            if (result['status'].toLowerCase() == "success") {

                if (result.hasOwnProperty('versioncontrolno')) {

                    if (vct && vct != result['versioncontrolno']) {

                        console.log("VERSION chk", vct+"===="+result['versioncontrolno'])

                        localStorage.setItem("versionControllNum", '');

                        this.logout(1);

                        confirm("PLEASE CLEAR CATCHE AND RE-LOGIN.")


                    } else {

                        console.log("VERSION SET", result['versioncontrolno'])

                        localStorage.setItem("versionControllNum", result['versioncontrolno']);

                    }

                }

            }

        })



    }


    ////////////////////////////////////////////////////////////// 
    sendMessage(text) {
        this.subject.next(text);
    }

    getMessage(): Observable<any> {
        return this.subject.asObservable();
    }


    //////////////////////////////////////////////////////////////

    async presentloading(data) {


        if (!data) {
            this.repeatServiceVoidTime = 0;
            setTimeout(() => {
                this.repeatServiceVoidTime = 1;
            }, 5000); 

        }




        if (this.isLoading) return;
        this.isLoading = true;
        return await this.loadingController.create({
            spinner: "circles",
            //duration: 1000,
            message: data,
            translucent: true,
            cssClass: 'custom-class custom-loading'
        }).then(a => {
            a.present().then(() => {
                //   console.log('presented');
                if (!this.isLoading) {
                    a.dismiss().then(() => {
                        // console.log('abort presenting')

                    }


                    );
                }
            });
        });
    }

    //////////////////////////////////////////////////////////////
    alertapi() {

        //	alert(this.apiUrl);

    }

    //////////////////////////////////////////////////////////////

    async dismissloading() {

        if (!this.isLoading) return;

        this.isLoading = false;
        try {

            return await this.loadingController.dismiss().then(() => console.log('dismissed'));

        } catch (e) {


        }

    }

    //////////////////////////////////////////////////////////////
    async presentAlert(msg, val?) {

        const alert1 = await this.alertController.create({

            header: 'Alert',

            message: msg,

            buttons: [{

                text: 'OK',

                handler: () => {

                    val ? this.confirmActions(val) : "";
                }
            },

            {
                text: 'Cancel',

                handler: () => {

                }
            }

            ]
        });

        await alert1.present();

    }




    async presentAlerttna(msg, val?) {
        const alert1 = await this.alertController.create({
            header: 'Alert',
            message: msg,

            buttons: [{
                text: 'OK',
                handler: () => {
                    val ? this.confirmActions(val) : "";
                }
            },

                // {
                //     text: 'Cancel',
                //     handler: () => {

                //     }
                // }



            ]
        });

        await alert1.present();

    }

    //////////////////////////////////////////////////////////////
    showToast(msg) {
        this.myToast = this.toast.create({
            message: msg,
            duration: 1500
        }).then((toastData) => {
            console.log(toastData);
            toastData.present();
        });
    }

    HideToast() {
        this.myToast = this.toast.dismiss();
    }

    //////////////////////////////////////////////////////////////


    confirmActions(val) {

        if (val == "reload") {

            this.reloadCurrentRoute();

        } else if (typeof (val) == 'object') {

            this.callservice(val).then(result => {

                if (result['status'].toLowerCase() == "success") {

                    this.showToast('Success');

                    setTimeout(() => {

                        this.reloadCurrentRoute();

                    }, 2000)


                } else {

                    this.presentAlert(result['message']);

                }

            })

        }

    }


    //////////////////////////////////////////////////////////////  

    async logout(noconfirm?) {

        if (noconfirm) {

            localStorage.setItem("SMTOOL_loginstorage", "");

            localStorage.setItem("SMTOOL_menustorage", "");

            localStorage.setItem("versionControllNum", "");

            this.userData = '';

            this.activeusername = '';

            //this.router.navigate(['/']);

            window.location.assign('/');


        } else {

            const alert = await this.alertController.create({
                cssClass: 'my-custom-class',
                header: 'Confirm!',
                message: ' <strong>Would you like to logout?</strong>',
                buttons: [{
                    text: 'NO',
                    role: 'cancel',
                    cssClass: 'secondary',
                    handler: (blah) => {
                        console.log('Confirm Cancel: blah');
                    }
                }, {
                    text: 'YES',

                    handler: () => {

                        localStorage.setItem("SMTOOL_loginstorage", "");

                        localStorage.setItem("SMTOOL_menustorage", "");

                        localStorage.setItem("versionControllNum", "");

                        //    this.router.navigate(['/']);

                        window.location.assign('/');

                        console.log('Confirm Okay');
                    }
                }]
            });

            await alert.present();


        }

    }

    ////////////////////////////////////////////////////////////// 

    navigatefun(act) {

        if (act == 'logout') {

            localStorage.setItem("SMTOOL_loginstorage", "");

            localStorage.setItem("SMTOOL_menustorage", "");

            localStorage.setItem("versionControllNum", "");

            this.userData = '';

            this.activeusername = ''

            this.logout();

        } else if (act == 'changepassword') {

            this.router.navigateByUrl('/changepassword', {
                state: {
                    "username": this.userData['logindetails'][0]['email'],
                    "from": "pages"
                }
            });

        } else if (act == 'glos') {

            window.open("../../assets/data/GLOSSARY.pdf");

        } else if (act == 'form') {

            window.open("../../assets/data/FORMULAE.pdf");

        } else if (act == 'help') {

            let pag = window.document.title; let fromPage = this.router.url.substr(1);;

            // confirm(pag+': This page help is Under Construction');





            var myWindow = window.open("../../assets/data/help.html?tags=" + (pag + "_" + fromPage), "MsgWindow", "width=820,height=550");

            myWindow.document.title = "HELP";


            myWindow.focus();


        } else {

            let fromPage = this.router.url;

            //     alert(fromPage);

            this.router.navigate([fromPage]);

        }

    }


    reloadCurrentRoute() {

        setTimeout(() => {

            // alert('PAGE RELOAD STOPPED FOR CHKING. ');

            window.location.reload();

        }, 1500);

    }


    ///////////////



    chkPageValidityDates(page) { 
      

        let isavailed = 1;

        let menuData = localStorage.getItem("SMTOOL_menustorage");
       
        if (menuData) {
           
            for (var q in this.menuData) {

                if (this.menuData[q]['pagename']) {
                    

                    for (var qq in this.menuData[q]['pagename']) {

                        if (page == this.menuData[q]['pagename'][qq]['page_code']) {

                            var thisPageData = this.menuData[q]['pagename'][qq];

                        }

                    }

                }

            }

           // console.log("thisPageData", thisPageData);

            let cd = new Date().getDate();

            let cm = new Date().getMonth() + 1;

            let cy = new Date().getFullYear();

          
            if (!thisPageData['validity_from']) return { "valid": true, msg: '' };


            let vf = thisPageData['validity_from'].split("-")[2];

            let vt = thisPageData['validity_to'].split("-")[2];


            //   alert(cd + ":   " + vf + ":" + vt);



            let valid = (cd >= vf && cd <= vt);



            if (cm == 12 && !valid) {

                cm = 1;

                cy++;

            }


            let msg = "Add/edit will be enabled between " + (vf + "-" + cm + "-" + cy) + " and " + (vt + "-" + cm + "-" + cy) + ".";

            let PageAccessDate = { "valid": valid, msg: msg };

            console.log("PageAccessDate", PageAccessDate);

            return PageAccessDate;

        } else { }

    }





    logoutCalled = 0;


    chk_localstorage(page?) {


        setTimeout(() => {


         //   this.versioncontrolFun();

        }, 1000);



        let isavailed = 1;

        this.userData = localStorage.getItem("SMTOOL_loginstorage");

        this.menuData = localStorage.getItem("SMTOOL_menustorage");

        if (this.userData) {

            this.menuCtrl.enable(true);

            this.userData = JSON.parse(this.userData);

            this.menuData = JSON.parse(this.menuData);

            var thisPageData: any = {};

            let loggedIn: any = ((+(new Date(this.userData['logindetails'][0]['login_date']))) / 86400000).toFixed(2);

            let currDate: any = ((+(new Date())) / 86400000).toFixed(2);

            //let loggedIn:any = ((+(new Date("2022-08-03"))) / 86400000).toFixed(2);

            // let currDate:any  = ((+(new Date())) / 86400000).toFixed(2);

        //    alert(currDate - loggedIn);

            if ((currDate - loggedIn) > 1) {

                this.logout(1);


            }




            if (this.userData['logindetails'][0]['username'] != 'admin') {

                // var perm_pages = "poentrysample poentrybulk povalidationsample povalidationbulk home changepassword role";

                var perm_pages = '';

                for (var q in this.menuData) {

                    if (this.menuData[q]['pagename']) {

                        for (var qq in this.menuData[q]['pagename']) {

                            perm_pages += this.menuData[q]['pagename'][qq]['page_code'] + " ";

                            if (page == this.menuData[q]['pagename'][qq]['page_code']) {

                                thisPageData = this.menuData[q]['pagename'][qq];

                                // alert(JSON.stringify(thisPageData))

                            }

                        }

                    } else {

                        perm_pages += this.menuData[q]['page_code'] + " ";
                    }

                }


                // alert(JSON.stringify(thisPageData));

                //    confirm(perm_pages);  confirm(page);

                if (perm_pages.indexOf(page) < 0) {

                    //  this.logout(1);

                    //  isavailed = 0;
                    //  return;

                }
            }

        } else {

            this.logout(1);

            isavailed = 0;
            return;

        };


        if (isavailed) this.crudBtnsToHide(thisPageData);

        return isavailed;


    }

    /////////////////////////service starts////////////////////
    

    



    userdetails;
    validuserlogin(params) {
        this.userdetails = params;
        localStorage.setItem("logindata", JSON.stringify(this.userdetails));

        let postData = new FormData();

        var username = this.generateBase64(params.username);
      var password = this.generateBase64(params.password);
            
      let headers = new HttpHeaders({
        
        'appid': 'NTU1NDI1',
                      
       // 'appsecret': 'NTU1NDI1K34rd2hiczEyMzE2NjU0K34rMTY2Njk5NDY1NA==',
       'appsecret': 'NTU1NDI1K34rd2hiczEyMzE2NjU0K34rMTY3MTIwOTI0MTI=',
 
    });  

        //postData.append('username', username);

       // postData.append('password', password);

        postData.append('application_url', this.application_url);

      //  postData.append('ipaddress', '192.168.16.77');
      this.ipaddress= localStorage.getItem('ipddress');
       postData.append('ipaddress', this.ipaddress);
       postData.append('username',username);
       postData.append('password',password);

       
     
        return new Promise((resolve, reject) => {
                  
            //    headers.append('Content-Type', 'application/json');
            this.http.post(this.apiUrl + 'uservalidation_post',postData,{ headers:headers })
                .subscribe(res => {
                    resolve(res);
                   // console.log(res, 'loginchecking')
                                     
                }, (err) => {

                    this.dismissloading();

                    this.presentAlert("Error occured. Please contact compobooking admin");

                    reject(err);
                });
        });
    }




    forgotpassword(params) {

        let username = this.generateBase64(params.username);
        let headers = new HttpHeaders({
          
            'appid': 'NTU1NDI1',
            //'appsecret': 'NTU1NDI1K34rd2hiczEyMzE2NjU0K34rMTY2Njk5NDY1NA==',
            'appsecret': 'NTU1NDI1K34rd2hiczEyMzE2NjU0K34rMTY3MTIwOTI0MTI=',
     
   
      }); 

        let postData = new FormData();

        postData.append('mailid', username);

        postData.append('application_url', this.application_url);
        this.ipaddress= localStorage.getItem('ipddress');
        postData.append('ipaddress', this.ipaddress );

        return new Promise((resolve, reject) => {
           // let headers = new Headers();
            headers.append('Content-Type', 'application/json');
           // this.http.post(this.apiUrl + 'login/forgotpassword', postData)
            this.http.post(this.apiUrl + 'forgotpassword', postData,{headers: headers})
                .subscribe(res => {
                    resolve(res);
                }, (err) => {

                    this.dismissloading();
                    this.presentAlert("Error occured. Please contact compobooking admin");

                    console.log(JSON.stringify(err));
                    reject(err);
                });
        });
    }




    passwordchange(params) {

        let username = this.generateBase64(params.username);
        let oldpassword = this.generateBase64(params.password);
        let password = this.generateBase64(params.newpassword);
        let confirmpassword = this.generateBase64(params.confirmpassword);

        let headers = new HttpHeaders({
          
            'appid': 'NTU1NDI1',
            //'appsecret': 'NTU1NDI1K34rd2hiczEyMzE2NjU0K34rMTY2Njk5NDY1NA==',
            'appsecret': 'NTU1NDI1K34rd2hiczEyMzE2NjU0K34rMTY3MTIwOTI0MTI=',
     
   
      }); 
        let postData = new FormData();

        postData.append('username', username);

        postData.append('oldpassword', oldpassword);

        postData.append('password', password);

        postData.append('confirmpassword', confirmpassword);

        postData.append('userseqnum', this.userData['logindetails'][0]['user_seq_num']);

        postData.append('application_url', this.application_url);

        postData.append('ipaddress', this.ipaddress);

        return new Promise((resolve, reject) => {
            //let headers = new Headers();
            headers.append('Content-Type', 'application/json');
            this.http.post(this.apiUrl + 'login/passwordchange', postData,{headers: headers})
                .subscribe(res => {
                    resolve(res);
                }, (err) => {
                    this.dismissloading();
                    this.presentAlert("Error occured. Please contact compobooking admin");

                    console.log(JSON.stringify(err));
                    reject(err);
                });
        });
    }

  


    /*   filedownloadimage(params) {
        let postData = new FormData();
        postData.append('username', params.username);
        postData.append('password', params.password);

      return new Promise((resolve, reject) => {
          let headers = new Headers();
          headers.append('Content-Type', 'application/json');
          this.http.get(params)
            .subscribe(res => {
              resolve(res);
            }, (err) => {

              this.dismissloading();
              this.presentAlert("Error occured. Please contact compobooking admin");

              console.log(JSON.stringify(err));
              reject(err);
            });
        });
     


      }


     */


    //   SubmitForm() {
    //     if (!this.firstFormGroup.valid && !this.secondFormGroup.valid && !this.thirdFormGroup.valid) {
    //       Swal.fire("Please fill  mandatory field in the form");
    //       return;
    //     }
    
    //     var firstform: string = btoa(JSON.stringify(this.firstFormGroup.value));
    //     var seconform: string = btoa(JSON.stringify(this.secondFormGroup.value));
    //     var thirdform: string = btoa(JSON.stringify(this.thirdFormGroup.value));
    //     // this.dataparam.push(JSON.stringify(this.firstFormGroup.value),JSON.stringify(this.secondFormGroup.value),JSON.stringify(this.thirdFormGroup.value));
    //     console.log(this.dataparam, 'datapram')
    //     var data: string = btoa(this.dataparam);
    
    //     let headers = new HttpHeaders({
    //       'Content-Type': 'application/json',
    //       'responseType': 'json',
    //       'username': this.localdata.username,
    //       'password': this.localdata.password,
    //       'appid': "APP001",
    //       'secret': "test12345",
    //     });
    
    
    //     var body = {
    //       "projectdetail": firstform,
    //       "projectcost": seconform,
    //       "annualoperating": thirdform,
    //     }
    //     this.http.post(this.url + 'addbudgetrequest/', body, { headers: headers }).subscribe(res => {
    //       if (res['status'] == "Success") {
    //         Swal.fire(res['info'])
    
    //       } else {
    //         Swal.fire(res['info'])
    
    //       }
    
    //     })
    
    //   }
    
    




//     getmenulist(params) {


//         var username = this.generateBase64(this.userdetails.username);
//       var password = this.generateBase64(this.userdetails.password);

//         let headers = new HttpHeaders({
//             'Content-Type': 'application/json',
//             'responseType': 'json',
//             'username': username,
//             'password': password,
     
//         });  
      

//         let postData = new FormData();

// for (var q in params) {

//     postData.append(q, params[q]);

// }
//         return new Promise((resolve, reject) => {
            
//             this.http.post(this.apiUrl + params['path'],postData, { headers: headers })
//                 .subscribe(res => {
//                     resolve(res);
//                 }, (err) => {

//                     this.dismissloading();
//                     this.presentAlert("Error occured. Please contact compobooking admin");

//                     console.log(JSON.stringify(err));
//                     reject(err);
//                 });
//         });



//     }

// systemip(ip){
//    this.ipaddress=ip;
//    console.log(this.ipaddress,"data");
// }

    getmenulist(params) {
        let postData = new FormData();  

        
       var username = this.generateBase64(this.userdetails.username);
        var password = this.generateBase64(this.userdetails.password);
  
        let headers = new HttpHeaders({
          
            'appid': 'NTU1NDI1',
            //'appsecret': 'NTU1NDI1K34rd2hiczEyMzE2NjU0K34rMTY2Njk5NDY1NA==',
            'appsecret': 'NTU1NDI1K34rd2hiczEyMzE2NjU0K34rMTY3MTIwOTI0MTI=',
     
   
      });  
      

        for (var q in params) {

            postData.append(q, params[q]);

        }
        postData.append('application_url', this.application_url);
       // postData.append('ipaddress', '192.168.16.77');
       this.ipaddress= localStorage.getItem('ipddress');
       postData.append('username', username);
       postData.append('password', password);
       postData.append('ipaddress', this.ipaddress);
       

        return new Promise((resolve, reject) => {
            // let headers = new Headers();
             headers.append('Content-Type', 'application/json');
             //headers.append('username', username)
            // headers.append('password', password)
             // console.log(params,"rdgdrg")

            //   this.http.post(this.apiUrl + 'uservalidation_post/',postData,{ headers: headers })
            //   .subscribe(res => {
            //       resolve(res);
            this.http.post(this.apiUrl + params['path'] , postData, { headers: headers})
                .subscribe(res => {   
                    
                    resolve(res); 
                    
                    

                }, (err) => {

                    this.dismissloading();
                    this.presentAlert("Error occured. Please contact compobooking admin");

                    console.log(JSON.stringify(err));
                    reject(err);
                });
        });



    }
    removeLoaderScreen() {

        try { $('.loadingmyoverlay').remove(); } catch (err) { }

        $('.buttonsdiv .addbutton').attr("disabled", false);

        $('.buttonsdiv .addbutton').prop("disabled", false);

        $('.buttonsdiv .savebutton').attr("disabled", false);

        $('.buttonsdiv .savebutton').prop("disabled", false);


    }




    clearPrevParams(path) {

        setTimeout(() => {

            for (let q = 0; q < this.calledApiArray.length; q++) {

                //	console.error(this.calledApiArray[q]+ "--"+path)

                if (this.calledApiArray[q] == path) {

                    this.calledApiArray.splice(q, 1);

                    break;
                }

            }


        }, 2000)

    }



    calledApiArray = [];


    callservice(params) {
        
      //  console.log(this.userData, 'logindetail')
        var chk =  JSON.parse(localStorage.getItem('logindata'));

       //   console.log(chk ,"logindetails");
        var username = this.generateBase64(chk.username);
        var password = this.generateBase64(chk.password);
          
       
        let headers = new HttpHeaders({
          
            'appid': 'NTU1NDI1',
            //'appsecret': 'NTU1NDI1K34rd2hiczEyMzE2NjU0K34rMTY2Njk5NDY1NA==',
            'appsecret': 'NTU1NDI1K34rd2hiczEyMzE2NjU0K34rMTY3MTIwOTI0MTI=',
   
      });  


     // console.log(headers, 'headers')
        let index = this.calledApiArray.indexOf(params['path']);
                                                
        if (index >= 0 && params['path'] != "Quality/qualitydetails") {
          
             if (this.repeatServiceVoidTime == 1) params['path'] = "common/frontendsave";

        } else {

            this.calledApiArray.push(params['path']);

            //     console.log('this.calledApiArray',     this.calledApiArray);	 

            this.clearPrevParams(params['path']);

        }



        this.tracer(params);


        try { $('.loadingmyoverlay').remove(); } catch (err) { }



        $('body').append('<div class="loadingmyoverlay"><div style= "color:black;margin-left:50%;margin-top:50%";></div></div>');


        $('.buttonsdiv .addbutton').attr("disabled", true);

        $('.buttonsdiv .addbutton').prop("disabled", true);

        $('.buttonsdiv .savebutton').attr("disabled", true);     // $('.buttonsdiv .savebutton').hide();

        $('.buttonsdiv .savebutton').prop("disabled", true);


        setTimeout(() => {


            try { $('.loadingmyoverlay').remove(); } catch (err) { }

            $('.buttonsdiv .addbutton').attr("disabled", false);

            $('.buttonsdiv .addbutton').prop("disabled", false);

            $('.buttonsdiv .savebutton').attr("disabled", false);

            $('.buttonsdiv .savebutton').prop("disabled", false);




        }, 5000)


        this.presentloading("Loading...");

        let postData = new FormData();

        for (var q in params) {

            //  console.log(typeof params[q]);

            //   if (typeof params[q] != 'object') 

            postData.append(q, params[q]);

        }

        if (params.hasOwnProperty('FILES_UPLOAD_KEYS_ARR')) {

            for (var i in params['FILES_UPLOAD_KEYS_ARR']) {

                let keyname = params['FILES_UPLOAD_KEYS_ARR'][i];

                for (var q in params[keyname]) {

                    postData.append(keyname + '[]', params[keyname][q]);

                }

            }

        }


        postData.append('userid', this.userData['logindetails'][0]['user_seq_num']);

        postData.append('roleid', this.userData['logindetails'][0]['role_seq_num']);
        postData.append('loginusername', this.userData['logindetails'][0]['username']);

        if (this.userData['logindetails'][0]['supplier_seq_num']) {

            var logsup = [];
			
			var loginsuppliername = []; 
			
			var loginsuppliershortname = [];

            for (var w in this.userData['logindetails']) {

                logsup.push(this.userData['logindetails'][w]['supplier_seq_num']);
				
				  loginsuppliername.push(this.userData['logindetails'][w]['supplier_name']);
				  
				    loginsuppliershortname.push(this.userData['logindetails'][w]['supplier_shortname']);

            }

            postData.append('loginsupplierseqnum', String(logsup));
			
			     postData.append('loginsuppliername', String(loginsuppliername));
				 
				      postData.append('loginsuppliershortname', String(loginsuppliershortname));
			

        }





        if (this.userData['logindetails'][0]['supplier_seq_num']) {

            var logsup = [];

            for (var w in this.userData['logindetails']) {

                logsup.push(this.userData['logindetails'][w]['supplier_seq_num']);

            }

            postData.append('loginsupplierseqnum', String(logsup));

        }





        //  alert(logsup)
 
        /*    if(logsup == null){
              postData.append('loginsupplierseqnum', '');
      
           }else{
              postData.append('loginsupplierseqnum', logsup || '');
      
      
      
           } */

        //   alert("loginsupplierseqnum:" + logsup);
        //var password = this.generateBase64(chk.password);
        //var username = this.generateBase64(chk.username);
        postData.append('username',username);
        postData.append('password',password);
        postData.append('role_order', this.userData['logindetails'][0]['role_order']);

        postData.append('application_url', this.application_url);
       // postData.append('ipaddress', '192.168.16.77');
       //  postData.append('type', 'master');
      //  postData.append('color_code','c77');
      
                  
      this.ipaddress= localStorage.getItem('ipddress');
      postData.append('ipaddress',  this.ipaddress);
      

        

        return new Promise((resolve, reject) => {
           // let headers = new Headers();
           // headers.append('Content-Type', 'application/json');
           
            this.http.post(this.apiUrl + (params['path']).trim(), postData, { headers: headers })
           
                .subscribe(res => {
                    resolve(res);


                    this.tracer(res, "Respo-" + params['path']);

                    this.dismissloading();

                    params = {};

                    //	try { $('.loadingmyoverlay').remove();  }catch(err) {}

                    this.removeLoaderScreen();

                }, (err) => {

                    //  this.presentAlert("Network Error.Please try later");

                    this.presentAlert("Error. Please contact compobooking admin");

                    this.dismissloading();

                    reject(err);
                });
        });




    }


    get_lastupdate(params) {


        let postData = new FormData();
        let headers = new HttpHeaders({
          
            'appid': 'NTU1NDI1',
           // 'appsecret': 'NTU1NDI1K34rd2hiczEyMzE2NjU0K34rMTY2Njk5NDY1NA==',
           'appsecret': 'NTU1NDI1K34rd2hiczEyMzE2NjU0K34rMTY3MTIwOTI0MTI=',
     
   
      }); 

        for (var q in params) {

            postData.append(q, params[q]);

        }
        var chk =  JSON.parse(localStorage.getItem('logindata'));

        //   console.log(chk ,"logindetails");
         var username = this.generateBase64(chk.username);
         var password = this.generateBase64(chk.password);
        postData.append('username',username);
        postData.append('password',password);
        this.ipaddress= localStorage.getItem('ipddress');
        postData.append('ipaddress',  this.ipaddress);
        postData.append('userid', this.userData['logindetails'][0]['user_seq_num']);

        postData.append('roleid', this.userData['logindetails'][0]['role_seq_num']);

        postData.append('role_order', this.userData['logindetails'][0]['role_order']);

        postData.append('application_url', this.application_url);
       

        return new Promise((resolve, reject) => {
           // let headers = new Headers();
            headers.append('Content-Type', 'application/json');
            this.http.post(this.apiUrl + params['path'], postData,{ headers: headers })
                .subscribe(res => {
                    resolve(res);


                }, (err) => {


                    this.presentAlert("Error. Please contact compobooking admin");


                    reject(err);
                });
        });



    }

   /*  setDateToNgmodel(val){
       
        if(val == null)
        {
          return ;
        }

        else{
      return new Date(val);
      }
  }
  
  sendDateToService( val){
      
      let dat:any = new Date(val);	
      
      let dd= (String(dat.getDate())).padStart(2,"0");;	
      
      let mm=(String(1+dat.getMonth())).padStart(2,"0");;	
      
      let yy= dat.getFullYear();
      
      return(yy+"-"+mm+"-"+dd);
      
      } */
      setDateToNgmodel(val) {

        if (!val) return '';

        return new Date(val);

    }

    sendDateToService(val) {

        if (!val) return '';

        let dat: any = new Date(val);

        let dd = (String(dat.getDate())).padStart(2, "0");;

        let mm = (String(1 + dat.getMonth())).padStart(2, "0");;

        let yy = dat.getFullYear();

        return (yy + "-" + mm + "-" + dd);

    }

    downloaddoc() {

        //  alert(this.router.url.substr(1))


        let params = {

            "pagename": this.router.url.substr(1),

            "path": 'common/pageheading'
        };


        this.presentloading("Downloading...");

        this.callservice(params).then(result => {


            setTimeout(() => {

                this.dismissloading();

            }, 3000);

            if (result['status'] == "Success") {

                window.open(this.apiUrl + (result['filepath'].substr(2)));

            } else {

                this.presentAlert(result['message']);

            }

        })

    }


    uploaddoc(f, s, e) {

        let params = {
            "importdatacsv": f,
            "path": (s.charAt(0) == '*') ? s.substr(1) : s + '/importfile'
        };

        this.presentloading("Uploading...");

        this.callservice(params).then(result => {

            e.value = '';

            setTimeout(() => {

                this.dismissloading();

                window.location.reload();

                // e.value = '';

            }, 3000);

            if (result['status'] == "Success") {

                this.presentAlert('File uploaded sucessfully');


            } else {

                this.presentAlert("Error: " + result['message'])


            }

        })

    }

    public fileupload(files: FileList, servicename, divid?) {

        var seletectedFile: any = files[0];

        var pageName = this.router.url.substr(1);

        //   alert("pageName " + pageName +":"+seletectedFile.name);

        if ((pageName + ".csv") != seletectedFile.name) {

            this.presentAlert('Please upload "' + pageName + '.csv" file');

            divid.value = '';


            return;

        } else {



            this.uploaddoc(seletectedFile, servicename, divid)

        }

    }

    additonalParams: any = '';


    downloadexcel(page, filetype, arg?, type?) {

        let params = {

            /*       
               postData.append('userid', this.userData['logindetails'][0]['user_seq_num']);

               postData.append('roleid', this.userData['logindetails'][0]['role_seq_num']); */


            // "path": page + '/excel',


            "path": (page.charAt(0) == '*') ? page.substr(1) : page + '/excel',

            "po_type": arg,

            "filetype": filetype,

            "season_type": type


        };


        if (this.additonalParams) {

            params['seasonseqnum'] = this.additonalParams['seasonseqnum'];

            params['supplierseqnum'] = this.additonalParams['supplierseqnum'];

            params['status'] = this.additonalParams['status'];


        }


        this.presentloading("Downloading...");

        this.callservice(params).then(result => {


            setTimeout(() => {

                this.dismissloading();


            }, 3000);

            if (result['status'].toLowerCase() == "success") {

                window.open(this.apiUrl + (result['filepath'].substr(2)));


            } else {

                this.presentAlert(result['message'])


            }
  
            this.additonalParams = '';

        })


    }
    downloadcsv(page, filetype) {

        window.open(this.apiUrl + (page.substr(2)));

    }

    ///////////////////////////////////////////

    generateBase64(key, ts?) {

        //alert("key"+key);

        var Timestamp = ts ? (key + '##' + (Math.floor(new Date().getTime() * 1000))) : key;

        let key64 = Base64.encode(Timestamp);

        //  console.log("key=" + key + ";Base64=" + key64);

        return key64;


    }

    /////////////////////////////////////////////// 
    sortArrOrder(arr, key) {

        if (arr.length) {

            return arr.sort((a, b) => String(a[key]).localeCompare(String(b[key])));

        } else {

            return arr;
        }


    }
    ///////////////////////////////////////////////

    sortArrNumOrder(arr, key, ord?) {

        if (arr.length) {


            if (ord) {

                return arr.sort((a, b) => Number(b[key]) - Number(a[key]));

            } else {

                return arr.sort((a, b) => Number(a[key]) - Number(b[key]));

            }



        } else {

            return arr;
        }


    }

    /////////////////////////////////////////////// anwar@whindia.in

    emailvalidate($event) {

        let email = $event;

        if (email == '' || email == 'undefined' || email == undefined) return false;

        var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        if (!re.test(String(email).toLowerCase())) {

            this.presentAlert('Invalid Email');

            return false;

        }


        return true;
    }


    ///////////////////////////////////////////////
    gstvalidation(gstinVal) {

        // let gstinVal = '11AAAAA1111Z1A1';
        if (gstinVal == '' || gstinVal == 'undefined' || gstinVal == undefined) return 0;
        let reggst = /^([0-9]){2}([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}([0-9]){1}([a-zA-Z]){1}([0-9]){1}?$/;

        if (!reggst.test(gstinVal) && gstinVal != '') {

            return (0);

        } else {

            return (1);

        }
    }


    ///////////////////////////////////////////////
    panvalidation(panVal) {

        // let panVal = $('#panNumber').val();


        if (panVal == '' || panVal == 'undefined' || panVal == undefined) return 0;


        var regpan = /^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/;

        if (!regpan.test(panVal) && panVal != '') {

            return (0);

        } else {

            return (1);
        }


    }
    ///////////////////////////////////////////////




    keyPressNumbers(event: any) {
        const pattern = /[0-9\+\-\ ]/;
        let inputChar = String.fromCharCode(event.charCode);


        // console.log(inputChar)
        //  console.log(event.keyCode)


        if (event.keyCode != 8 && !pattern.test(inputChar)) {
            // event.preventDefault();
        }
    }




    //////////////////////////////////////////////////////
    get_list_of_countries() {
        return new Promise((resolve, reject) => {
            let headers = new Headers();
            headers.append('content-type', 'application/json');
            this.http.get('../../assets/jsondata/listCountries.json')

                .subscribe(res => {
                    resolve(res);
                    //alert(JSON.stringify("service result countries"+JSON.stringify(res)));
                }, err => {
                    reject(err);
                    // alert("service result error"+JSON.stringify(err))
                })
        })

    }




    getState() {

        return new Promise((resolve, reject) => {
            let headers = new Headers();
            headers.append('content-type', 'application/json');
            this.http.get('../../assets/jsondata/listStates.json')

                .subscribe(res => {
                    resolve(res);
                }, err => {
                    reject(err)

                })
        })
    }



    getcity() {

        return new Promise((resolve, reject) => {
            let headers = new Headers();
            headers.append('content-type', 'application/json');
            this.http.get('../../assets/jsondata/listCities.json')

                .subscribe(res => {
                    resolve(res);
                }, (err) => {
                    reject(err)

                })
        })
    }



    istoPrint = false;


    tableprintFun() {

        this.istoPrint = true;

        setTimeout(() => {
            var divToPrint = document.getElementById("tableprintdiv");
            var newWin = window.open("");
            newWin.document.write(divToPrint.outerHTML);
            newWin.print();
            newWin.close();
            this.istoPrint = false;

        }, 10);

    }



    crudBtnsToHide(thisPageData) {

        //    alert(JSON.stringify(thisPageData));

        $('.buttonsdiv .addbutton').show();
        $('.buttonsdiv .editbutton').show();
        $('.buttonsdiv .printbtn').show();
        $('.buttonsdiv .savebutton').show();


        setTimeout(() => {

            if (this.userData['logindetails'][0]['username'] != 'admin') {

                if (thisPageData['add_page'] == 0) $('.buttonsdiv .addbutton').hide();
                if (thisPageData['edit_page'] == 0) $('.buttonsdiv .editbutton').hide();
                if (thisPageData['print_page'] == 0) $('.buttonsdiv .printbtn').hide();
                if (thisPageData['add_page'] == 0 && thisPageData['edit_page'] == 0) $('.buttonsdiv .savebutton').hide();

            }

            $('.buttonsdiv').css('visibility', 'visible');


        }, 2100) //2100
    }


    crudBtnsDisabled(type) {

        $('.buttonsdiv .addbutton').attr("disabled", true);

        $('.buttonsdiv .addbutton').prop("disabled", true);

        $('.buttonsdiv .editbutton').attr("disabled", true);

        $('.buttonsdiv .editbutton').prop("disabled", true);

        if (type == "edit") $('.buttonsdiv .addbutton').css("opacity", 0.5);

        if (type == "add") $('.buttonsdiv .editbutton').css("opacity", 0.5);


    }


    isNumberEntered(obj, allowed) {


        //

        var isZero = allowed.indexOf('zero') > -1;

        var toFix: any = String(allowed).indexOf('tofix');

        toFix = toFix < 0 ? 0 : String(allowed).charAt(toFix + 5);

        console.log(String(allowed), toFix);

        let val = +(obj.target.value);

        if (isNaN(val)) { return obj.target.value = '' };



        if (!isZero && val < 1) return obj.target.value = '';;

        return obj.target.value = Number(Number(val).toFixed(toFix));




    }


    isNumber($event, allowed) {


        var isDecimal = allowed.indexOf('deci') > -1;

        var isZero = allowed.indexOf('zero') > -1;


        var toFix: any = String(allowed).indexOf('tofix');

        toFix = toFix < 0 ? 0 : String(allowed).charAt(toFix + 5);


        //  console.log(allowed + '=> isDecimal:' + isDecimal + ' isZero:' + isZero + ' toFix:' + toFix)


        var charCode = ($event.which) ? $event.which : $event.keyCode;

        var number = $event.target.value.split('.');

        if (charCode == 8) return true;


        if (charCode != 46 && charCode > 31 && (charCode < 48 || charCode > 57)) return false;

        if ((number.length > 1 || !isDecimal) && charCode == 46) return false;

        if (!isZero && charCode == 48 && $event.target.value == '') { return false; }

        //get the carat position



        var caratPos = this.getSelectionStart($event.target);

        var dotPos = $event.target.value.indexOf(".");

        // console.log(caratPos+":"+dotPos+":"+number.length+":"+(toFix - 1));


        if ((number.length >= 2) && (caratPos > dotPos && dotPos > -1 && (number[1].length > (toFix - 1)))) return false;

        return true;
    }

    getSelectionStart(o) {

        return o.selectionStart;
    }

    //onnumPress


    dateConverter(str) {
        var date = new Date(str),

            mnth = ("0" + (date.getMonth() + 1)).slice(-2),
            day = ("0" + date.getDate()).slice(-2);
             return [date.getFullYear(), mnth, day].join("-");



        /*         var now = new Date(str);
        
                let t = new Date(now.getTime() - now.getTimezoneOffset() * 60000).toISOString();
        
                return String(new Date(t).toISOString()).split("T")[0];
        
         */




    }






    updateTableFilter(event, rawList, filterList) {


        var isFullSearch = event.keyCode === 13;

        const val = (event.target.value.toLowerCase()).trim();

        const temp = rawList.filter(function (d) {


            var obj = JSON.stringify(d).toLowerCase();


            //console.log(val+'=--------------='+obj)


            if (obj.indexOf(val) !== -1 || !val) { console.log(obj + "vv:" + val); return (true); }

			/*
						
            for (let i in d) {
       
                    if (String(i).indexOf('seq_num') < 0) {

                        if (isFullSearch) {

                            if (String(d[i]).toLowerCase() == val || !val) {

                                return (true);

                            }

                        } else {
							
                          //  console.log(String(d[i]).toLowerCase()+"::"+val);
							
                            if (String(d[i]).toLowerCase().indexOf(val) !== -1 || !val) {

                                return (true);

                            }


                        }

                    }
	
            }

			
			*/



        });


        if (temp.length == 0) {
            this.presentAlert('No results');
            return (rawList)
        };

        //alert(temp.length)
        return filterList = JSON.parse(JSON.stringify(temp));

    }

   


    colorNames = [


        '#3cb44b', '#ffe119', '#6687ff', '#f58231', '#e590ff',
        '#46f0f0', '#f032e6', '#bcf60c', '#fabebe', '#008080',
        '#e6beff', '#9a6324', '#fffac8', '#800000', '#aaffc3',
        '#808000', '#ffd8b1', '#000075', '#808080'




    ]
    showHideTracer() {

        this.showTracer ^= 1; alert(this.showTracer);

    }

    tracer(params, title?) {





        if (this.showTracer == 0) return;

        let tit = title ? title : 'Req';

        let jparams = JSON.stringify(params, null, 2);

        var myWindow = window.open("", "MsgWindow", "width=900,height=750");

        myWindow.document.title = "TRACER";

        myWindow.document.write("<div><div style = 'font-size:12px;color:red;' ondblclick= 'javascript:(this.parentElement.remove())' >DblClickToRemove</div><div ><textarea rows='2' cols='100'>" + tit + ":" + jparams + "</textarea></div></br></div>");

        myWindow.focus();
    }

    colors = [
        "Tomato", "Orange", "DodgerBlue", "MediumSeaGreen", "Gray",
        "SlateBlue", "Violet", "LightGray", "red", "aqua",
        "lightpink", "orangered", "yellow", "palegreen", "mediumpurple",
        "thistle", "chocolate", "darkgoldenrod", "yellowgreen", "fuchsia",

    ]

    machinename = [
        "DMS 18", "DMS 8", "DMS 17", "DMS 6", "DMS 16",
        "DMS 20", "DMS 15", "DMS4", "DMS5", "DMS2",
        "DMS3", "DMS 19", "DMS 10", "DMS 11", "DMS 9",
        "DMS 12", "DMS 13", "DMS 14",
    ]

    /////////////////////////////////

} 



///end export class  //



//http://192.168.16.127/smt/common/getStartAndEndDate   week =15,year =2022


// http://192.168.16.127/smt/common/getyearweek   date : 2022-04-10


/*


 sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
}

async  demo() {
    for (let i = 0; i < 5; i++) {
        console.log(`Waiting ${i} seconds...`);
        await sleep(i * 1000);
    }
    console.log('Done');
}

demo();


*/













