import { Component } from '@angular/core';

import { Platform } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { Router } from '@angular/router';

import { AuthService } from '../app/providers/auth.service';
import { HttpClient } from '@angular/common/http';

import { MenuController } from '@ionic/angular';



@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'], 	
	  preserveWhitespaces: true
})
export class AppComponent {

  apiUrl = "http://192.168.16.106:8000/";
  menulist: any = [];


  titlearr = ['Masters', "Transactions"]

  


  subscription: any;

  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    public http: HttpClient,
    public authservice: AuthService,
    private router: Router,

    public menuCtrl: MenuController

  ) { 
     this.systemIp();
    this.initializeApp();
    

    this.subscription = this.authservice.getMessage().subscribe(text => {

      this.menulist = text.created;

      this.menuCtrl.enable(true);
 
    })


    setTimeout(() => {

      if (this.menulist.length == 0) {

        let itm = localStorage.getItem("SMTOOL_menustorage");

        if (itm) this.menulist = JSON.parse(itm);

        this.menuCtrl.enable(true);

      }
    }, 2000)

  }
  ipaddress:any=[];
    systemIpaddress:any='';
  systemIp()
    { 
        this.http.get(this.apiUrl+'getipaddress').subscribe((res=>{
           // console.log(res);
            this.ipaddress=res;
            this.systemIpaddress =this.ipaddress.value;
          //  console.log(this.systemIpaddress,"ip");
          //  console.log(this.ipaddress.status);

            //this.authservice.systemip(this.systemIpaddress);
            localStorage.setItem('ipddress',this.systemIpaddress);
        }))
    }
   

  navigatepages(page) {
  
    if (page != 'undefined') { 

    //  this.authservice.versioncontrolFun();

   //   alert(page);
   
      this.router.navigate([page], {
        replaceUrl: true
        //, skipLocationChange:true
      });

    }
  }


  initializeApp() {
    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      this.splashScreen.hide();
    });
  }
}
